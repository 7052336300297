<template>
  <router-view v-if="$route.name == 'Instructor Rubric Edit'" />
  <router-view v-else-if="$route.name == 'Instructor Rubric View'" />
  <v-sheet  min-height="300" class="d-flex flex-column custom-border pb-2" v-else>
    <section v-if="!loading" class="">
      <v-tabs class="px-5 pt-3" v-model="tab" background-color="transparent" hide-slider show-arrows>
        <v-tab
          v-for="item in items"
          :key="item"
          class="text-capitalize poppins f13 fw600 secondary--text no-spacing"
          active-class="secondary-1--text"
          style="text-spacing: 0px"
        >
          {{ item }}
        </v-tab>
        <v-spacer />
      </v-tabs>
      <v-divider class="mb-1" />
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item">
          <CreateRubric v-if="item === 'Create'" :tab="tab" @goToTab="goToTab"/>
          <MyRubrics v-if="item === 'My Rubrics'" :rubrics="rubrics" />
          <RubricShareToOtherTable v-if="item === 'Shared to others'" :rubrics="rubrics"/>
          <RubricShareWithMe v-if="item === 'Shared with me'" :rubrics="rubrics" />
        </v-tab-item>
      </v-tabs-items>
    </section>
    <circular v-else class="ma-auto"/>
  </v-sheet>
</template>

<script>
import { mapActions, mapState } from "vuex";
import items from '../../constants/tabs/rubric/assessmentrubric'
import MyRubrics from "../../components/teacher/rubrics/MyRubric.vue";
import CreateRubric from "../../components/teacher/rubrics/CreateRubric.vue";
import RubricShareWithMe from "../../components/teacher/rubrics/RubricShareWithMe.vue";
import RubricShareToOtherTable from "../../components/teacher/rubrics/RubricShareToOtherTable.vue";

export default {
  components: {
    MyRubrics,
    CreateRubric,
    RubricShareWithMe,
    RubricShareToOtherTable,
  },
  data: () => ({
    loading: true,
    items: items,
  }),
  mounted() {
    this.getRubricsAction().then(() => {
      this.loading = false;
    });
  },
  computed: {
    tab: {
      get() {
        let tab = parseInt(this.$route.query.tab);
        return tab;
      },
      set(tab) {
        this.$router.replace({ query: { tab: tab, timestamp: Date.now() } });
      },
    },

    ...mapState("instructor", {
      rubrics: (state) => state.rubrics,
    }),
  },
  methods: {
    ...mapActions("instructor", 
    [ "getRubricsAction", 
      "showRubricSharedToOtherAction", 
      "showRubricSharedWithMeAction"]),

    goToTab(tab){
      this.tab = tab
    }
  },

  watch: {
    tab(val) {
      val == 3
        ? this.showRubricSharedToOtherAction()
        : val == 2
        ? this.showRubricSharedWithMeAction()
        : val == 1
        ? this.getRubricsAction()
        : "";
    },
  },
};
</script>
